import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import playAudio from '~frontend/utils/playAudio';

import IconMenu10 from '../../scss/images/icon-coin-bottom-menu.svg?react';
import IconMenu1 from '../../scss/images/icon-menu-1.svg?react';
import IconMenu2 from '../../scss/images/icon-menu-2.svg?react';
import IconMenu4 from '../../scss/images/icon-menu-4.svg?react';
import IconMenu8 from '../../scss/images/icon-menu-8.svg?react';
import IconEarn from '../../scss/images/icon-menu-14.svg?react';
import { useConfig, useProfile } from '../hooks';

const BottomMenuMobile: FunctionComponent = () => {
  const getConfig = useConfig();
  const getProfile = useProfile();
  const { t } = useTranslation();

  return (
    <nav className="mob-menu-bottom">
      <div className="mob-menu-bottom__nav">
        <NavLink
          to="/"
          onMouseEnter={playAudio}
          data-audio="3.mp3"
          data-volume="0.15"
          className="mob-menu-bottom__nav-item"
        >
          <span className="icon material-icons" data-icon="&#xe88a;"></span>
        </NavLink>
        {getConfig?.getBattlePass && (
          <NavLink
            to={getConfig?.getBattlePass.getUrl}
            onMouseEnter={playAudio}
            data-audio="3.mp3"
            data-volume="0.15"
            className="mob-menu-bottom__nav-item"
          >
            <IconMenu10 />
          </NavLink>
        )}
        {/* <NavLink
          to="/contract"
          onMouseEnter={playAudio}
          data-audio="3.mp3"
          data-volume="0.15"
          className="mob-menu-bottom__nav-item"
        >
          <IconMenu2 />
        </NavLink>

        <NavLink
          to="/upgrade"
          onMouseEnter={playAudio}
          data-audio="3.mp3"
          data-volume="0.15"
          className="mob-menu-bottom__nav-item"
        >
          <IconMenu4 />
        </NavLink> */}

        <NavLink
          to="/wheel"
          onMouseEnter={playAudio}
          data-audio="3.mp3"
          data-volume="0.15"
          className="mob-menu-bottom__nav-item"
        >
          <IconEarn />
        </NavLink>

        {getProfile && (
          <NavLink
            to="/profile"
            onMouseEnter={playAudio}
            data-audio="3.mp3"
            data-volume="0.15"
            className="mob-menu-bottom__nav-item"
          >
            <span className="icon material-icons" data-icon="&#xe87c;"></span>
          </NavLink>
        )}
      </div>
    </nav>
  );
};

export default BottomMenuMobile;
