import { FunctionComponent, memo } from 'react';
import _ from 'lodash';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import Currency from '../Currency';
import { Price } from '~ui/Price';
import GameChange from '../game/GameChange';
import { useConfig } from '~components/hooks';

export const SELL_ALL_ITEMS = gql`
  query GetSellAllItems($userId: Int!) {
    getStats(userId: $userId) {
      sumItems
      sumItemsOld
    }
  }
`;

interface Props {
  userId: number;
}

const SellAllItems: FunctionComponent<Props> = memo(({ userId }) => {
  const { t } = useTranslation();
  const getConfig = useConfig();

  const { loading, error, data, client } = useQuery(SELL_ALL_ITEMS, {
    notifyOnNetworkStatusChange: true,
    variables: { userId },
  });

  const onDone = async () => {
    client.cache.writeQuery({
      query: SELL_ALL_ITEMS,
      data: {
        getStats: {
          sumItems: 0,
          sumItemsOld: 0,
        },
      },
      variables: { userId },
    });
  };

  if (loading) {
    return null;
  } else if (error) {
    return <>SellAllItems | Error! {error.message}</>;
  }

  const { getStats } = data;

  if (_.toFinite(getStats.sumItems) <= 0) {
    return null;
  }

  return (
    <>
      <GameChange className="btn btn-sell" type={2} onDone={onDone}>
        {/*//Comment to hide +3% on sell SCRUM-151 */}
        {/* <div className="btn-sale-cost-wrap">
          <div className="btn-sale-cost">
            {getConfig.isEU ? (
              <>
                <Currency />
                <Price sum={getStats.sumItemsOld} />
              </>
            ) : (
              <>
                <Price sum={getStats.sumItemsOld} />
                <Currency />
              </>
            )}
          </div>
          <div className="btn-sale-cost percent">+3%</div>
        </div> */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="42"
          height="42"
          viewBox="0 0 42 42"
        >
          <path
            d="M1992,1385.667l16-16h-8V1367h13.333v13.333h-2.667v-8l-16,16Z"
            transform="translate(-1981.333 -1356.333)"
            fill="#fff"
          ></path>
        </svg>
        <span className="text">{t('Sell all for')}</span>
        <div className="cost">
          {getConfig.isEU ? (
            <>
              <Currency />
              <Price sum={getStats.sumItems} />
            </>
          ) : (
            <>
              <Price sum={getStats.sumItems} />
              <Currency />
            </>
          )}
        </div>
      </GameChange>
      {/*<a className="btn btn-withdraw" href="#">
        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
          <path
            d="M1992,1385.667l16-16h-8V1367h13.333v13.333h-2.667v-8l-16,16Z"
            transform="translate(-1981.333 -1356.333)"
            fill="#fff"
          ></path>
        </svg>
        <span className="text">{t("Send all")}</span>
      </a>*/}
    </>
  );
});

export default SellAllItems;
