import {
  FunctionComponent,
  useCallback,
  memo,
  useState,
  SyntheticEvent,
} from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import Modal from './Modal';
import { useModal } from './hooks';
import { useConfig } from '~components/hooks';
import { useGetItems } from '../item/hooks';
import { useGetGame } from '../game/hooks';
import GameChange from '../game/GameChange';
import { GetName, SteamItemImage } from '../item';
import Currency from '../Currency';
import { Price } from '~ui/Price';
import { Loader } from '../Loader';

interface Props {
  ids: number[];
  gameId: number;
  onClick: () => void;
}

const WithdrawView: FunctionComponent<Props> = memo(
  ({ ids, gameId, onClick }) => {
    const { t } = useTranslation();
    const [replaceItem, setReplaceItem] = useState(0);
    const getItems = useGetItems(ids);
    const getGame = useGetGame(gameId);
    const count = _.size(getItems);
    const getConfig = useConfig();

    const onSetReplaceItem = useCallback(
      (event: SyntheticEvent<HTMLElement>) => {
        setReplaceItem(Number(event.currentTarget.dataset.id));
      },
      [setReplaceItem],
    );

    return (
      <div className="withdraw-none-items-content">
        {getGame ? (
          <div className="withdraw-none-items-head">
            <div className="withdraw-none-items-head-title">
              <div className="title">{t('withdraw_none_title')}</div>
              <div className="text">
                {t('withdraw_none_text_0')} {count} {t('item', { count })}{' '}
                {t('withdraw_none_text_1')}
              </div>
              <div className="title-items">
                {t('withdraw_none_title_items')}:
              </div>
            </div>
            <div
              className="in-case-item active status-4"
              data-rar={getGame?.getItem?.color}
              data-audio="5.wav"
              data-volume="0.4"
            >
              <div className="in-case-cost">
                {getConfig.isEU ? (
                  <>
                    <Currency />
                    <Price sum={getGame?.price} />
                  </>
                ) : (
                  <>
                    <Price sum={getGame?.price} />
                    <Currency />
                  </>
                )}
              </div>
              <div className="in-case-item-wrap">
                <div className="img">
                  <div className="in-case-item-bg" />
                  <SteamItemImage
                    url={getGame?.getItem?.getImage}
                    size="350x350"
                  />
                </div>
                <div className="in-case-info">
                  <div className="in-case-item-name">
                    <GetName getItem={getGame?.getItem} type={0} />
                  </div>
                  <div className="in-case-item-desc">
                    <GetName getItem={getGame?.getItem} type={1} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Loader />
        )}
        <div className="withdraw-none-items-offer">
          {getItems ? (
            _.map(getItems, getItem => (
              <div
                className={`withdraw-none-items-offer-item ${
                  getItem.id === replaceItem && 'active'
                }`}
                key={getItem.id}
                data-id={getItem.id}
                onClick={onSetReplaceItem}
              >
                <div
                  className="in-case-item status-4"
                  data-rar="eb4b4b"
                  data-audio="5.wav"
                  data-volume="0.4"
                >
                  <div className="in-case-cost">
                    {getConfig.isEU ? (
                      <>
                        <Currency />
                        <Price sum={getItem.price} />
                      </>
                    ) : (
                      <>
                        <Price sum={getItem.price} />
                        <Currency />
                      </>
                    )}
                  </div>
                  <div className="in-case-item-wrap">
                    <div className="img">
                      <SteamItemImage url={getItem?.getImage} size="350x350" />
                      <div className="honey"></div>
                    </div>
                    <div className="in-case-info">
                      <div className="in-case-item-name">
                        <GetName getItem={getItem} type={0} />
                      </div>
                      <div className="in-case-item-desc">
                        <GetName getItem={getItem} type={1} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <Loader />
          )}
        </div>
        <div className="btns">
          <GameChange
            className="btn btn-withdraw"
            type={1}
            getGame={getGame}
            onDone={onClick}
            replaceItem={replaceItem}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="42"
              height="42"
              viewBox="0 0 42 42"
            >
              <path
                d="M1992,1385.667l16-16h-8V1367h13.333v13.333h-2.667v-8l-16,16Z"
                transform="translate(-1981.333 -1356.333)"
                fill="#fff"
              ></path>
            </svg>
            <span className="text">{t('send_trade')}</span>
          </GameChange>
          <GameChange
            className="btn btn-sell"
            type={0}
            getGame={getGame}
            onDone={onClick}
          >
            {/*SCRUM-151 hide sell price with bonus */}
            {/* <div className="btn-sale-cost">
              {getConfig.isEU ? (
                <>
                  <Currency />
                  <Price sum={getGame?.price} />
                </>
              ) : (
                <>
                  <Price sum={getGame?.price} />
                  <Currency />
                </>
              )}
            </div> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="42"
              height="42"
              viewBox="0 0 42 42"
            >
              <path
                d="M1992,1385.667l16-16h-8V1367h13.333v13.333h-2.667v-8l-16,16Z"
                transform="translate(-1981.333 -1356.333)"
                fill="#fff"
              ></path>
            </svg>
            <span className="text">{t('Sell')}</span>
            <div className="cost">
              {getConfig.isEU ? (
                <>
                  <Currency />
                  <Price sum={getGame?.priceSell} />
                </>
              ) : (
                <>
                  <Price sum={getGame?.priceSell} />
                  <Currency />
                </>
              )}
            </div>
          </GameChange>
        </div>
      </div>
    );
  },
);

export const WithdrawNone: FunctionComponent = () => {
  const modalName = 'WithdrawNone';

  const { onClose, isOpen, props } = useModal<Props>(modalName);

  const onClick = () => {
    onClose();
  };

  return (
    <Modal show={isOpen} onClose={onClick} width={732} modalName={modalName}>
      <div
        className="box-modal-wrap withdraw-none-items"
        id="withdraw-all-items"
      >
        <div className="box-modal">
          <div
            className="box-modal_close arcticmodal-close"
            onClick={onClick}
          ></div>
          <WithdrawView {...props} onClick={onClick} />
        </div>
      </div>
    </Modal>
  );
};
