import { FunctionComponent, memo, useEffect } from 'react';
import Modal from './Modal';
import { useTranslation } from 'react-i18next';
import { useYaMetrica } from '~frontend/provider/yametrica';
import { useModal } from './hooks';
import { useProfile, useConfig } from '../hooks';
import { result } from 'lodash';

interface BannerModalProps {
  id: number;
  onClose: any;
  modalText?: any;
  modalHeader?: any;
}

const BannerModal: FunctionComponent<BannerModalProps> = memo(
  ({ id = 0, onClose, modalText, modalHeader }: BannerModalProps) => {
    const getProfile = useProfile();
    const getConfig = useConfig();
    const { reachGoal } = useYaMetrica();
    const { t, i18n } = useTranslation();

    useEffect(() => {
      reachGoal('open_banner_modal');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
      <>
        <div className="box-modal_close arcticmodal-close" onClick={onClose}>
          <div className="icon material-icons">close</div>
        </div>
        <div className="modal-blue-title">
          {result(modalHeader, [i18n.language], modalHeader?.en)}
        </div>
        <div
          className="more-text"
          dangerouslySetInnerHTML={{
            __html: result(modalText, [i18n.language], modalText?.en),
          }}
        ></div>
      </>
    );
  },
);

const BannerModalWrapper: FunctionComponent = () => {
  const modalName = 'BannerModal';

  const { onClose, isOpen, props } = useModal<{
    id: number;
    modalText?: any;
    modalHeader?: any;
  }>(modalName);

  console.log('m1111odal text', props.modalText);

  return (
    <Modal show={isOpen} onClose={onClose} width={406} modalName={modalName}>
      <div className="box-modal modal-blue modal-blue-demo-game modal-profile-prize">
        <BannerModal
          id={props.id}
          onClose={onClose}
          modalText={props.modalText}
          modalHeader={props.modalHeader}
        />
      </div>
    </Modal>
  );
};

export default BannerModalWrapper;
