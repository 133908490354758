import {
  memo,
  FunctionComponent,
  SyntheticEvent,
  ReactNode,
  useCallback,
  MouseEventHandler,
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  forwardRef,
} from 'react';
import { useNavigate } from 'react-router';
import { Game as GameTypes } from '~types';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useMutation, gql } from '@apollo/client';
import _ from 'lodash';
import { Loader } from '~components/Loader';
import playAudio from '~frontend/utils/playAudio';
import { useOpenModal } from '~components/modals/hooks';
import { SELL_ALL_ITEMS } from '../profile/SellAllItems';
import { useProfile } from '../profile/hooks';
import { ApolloError } from 'apollo-server-errors';
import { Link } from 'react-router-dom';
import { number } from '~ui/Price';
import { Button } from '~shared/frontend/ui';
import { useConfig, useHover } from '../hooks';
import { useHoverDirty } from 'react-use';

const Sell_Item = gql`
  mutation SellItem($id: Int!) {
    sellItem(id: $id) {
      id
      status
      getStatus
      price
    }
  }
`;

const Send_Item = gql`
  mutation SendItem($id: Int!, $replaceItem: Int) {
    sendItem(id: $id, replaceItem: $replaceItem) {
      id
      status
      getStatus
    }
  }
`;

type GameChangeProps = {
  children?: ReactNode;
  getGame?: GameTypes;
  className: string;
  type?: number | 0;
  onDone?: any;
  gameIds?: Array<number> | [];
  onMouseOver?: MouseEventHandler;
  onMouseLeave?: MouseEventHandler;
  replaceItem?: number;
  sumPriceSell?: number;
  sumPrice?: number;
} & Omit<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  'type'
>;

const GameChange: FunctionComponent<GameChangeProps> = memo(
  forwardRef(
    (
      {
        getGame,
        className,
        type,
        onDone,
        children,
        gameIds,
        onMouseOver,
        onMouseLeave,
        replaceItem,
        ...rest
      },
      ref,
    ) => {
      const getConfig = useConfig();
      const navigate = useNavigate();
      const getProfile = useProfile();

      const refetchQueries = [
        {
          query: SELL_ALL_ITEMS,
          variables: {
            userId: getProfile?.id,
          },
        },
      ];

      const { t } = useTranslation();

      const { onShow } = useOpenModal();

      const viewModal = useCallback(
        (event, { type, reachgoal } = event.currentTarget.dataset) => {
          if (event) {
            event.preventDefault();
          }

          if (onDone) {
            onDone();
          }

          if (!type) {
            throw Error('Не указан тип');
          }

          onShow(type, { reachgoal, id: getGame?.id });
        },
        [onShow],
      );

      const [sellItem, { loading: loadingSellItem }] = useMutation(Sell_Item, {
        refetchQueries,
      });

      const [sendItem, { loading: loadingSendItem }] = useMutation(Send_Item, {
        refetchQueries,
      });

      const [sellAllItems, { loading: loadingMutation }] = useMutation(
        gql`
          mutation SellAllItems($input: InputSellAllItems!) {
            sellAllItems(input: $input) {
              id
            }
          }
        `,
        {
          refetchQueries,
        },
      );

      const goFree = () => {
        toast(
          <>
            <div className="notify-title">{t('Error - almost there')}!</div>
            <div
              className="notify-text"
              dangerouslySetInnerHTML={{
                __html: t('PAY_FREE_CASE', {
                  sum: getGame?.getCase?.seo?.inputMoney,
                  currency: getConfig?.getCurrency?.symbol,
                }),
              }}
            />
          </>,
        );
      };

      const onSellAllItems = async (event: SyntheticEvent) => {
        try {
          event.preventDefault();

          if (loadingMutation) {
            return false;
          }
          await sellAllItems({
            variables: { input: { id: gameIds } },
          });

          playAudio(null, '7.mp3');

          if (_.isFunction(onDone)) {
            onDone();
          }
        } catch (error) {
          toast.error(
            <>
              <div className="notify-title">{t('Error')}!</div>
              <div
                className="notify-text"
                dangerouslySetInnerHTML={{ __html: t(error.message) }}
              />
            </>,
          );
        }
      };

      const onSendItem = async (event: SyntheticEvent) => {
        try {
          event.preventDefault();

          if (replaceItem === 0) {
            throw new ApolloError('Выберите предмет!');
          }

          if (loadingSendItem) {
            return false;
          }

          await sendItem({
            variables: { id: getGame?.id, replaceItem },
            optimisticResponse: {
              sendItem: {
                id: getGame?.id,
                __typename: 'Game',
                status: 4,
                getStatus: t('Await send'),
              },
            },
          });

          playAudio(null, '7.mp3');

          if (_.isFunction(onDone)) {
            onDone();
          }
        } catch (errors) {
          for (const error of errors.graphQLErrors) {
            if (error.message === 'searchItem') {
              if (_.isFunction(onDone)) {
                onDone();
              }
              return false;
            } else if (error?.extensions?.type === 'WRITE_TRADE_URL') {
              navigate('/settings');
            } else if (error?.extensions?.type === 'PAY_FREE_CASE') {
              navigate('/case/free');

              return toast(
                <>
                  <div className="notify-title">
                    {t('Error - almost there')}!
                  </div>
                  <div
                    className="notify-text"
                    dangerouslySetInnerHTML={{ __html: t(error.message) }}
                  />
                </>,
              );
            } else if (error?.extensions?.type === 'DEPOSIT_EXPIRED') {
              //SCRUM-151
              navigate('/pay');
            }

            toast.error(
              <>
                <div className="notify-title">{t('Error')}!</div>
                <div
                  className="notify-text"
                  dangerouslySetInnerHTML={{ __html: t(error.message) }}
                />
              </>,
            );
          }
        }
      };

      const onSellItem = async (event: SyntheticEvent) => {
        event.preventDefault();

        if (loadingSellItem) {
          return false;
        }

        try {
          await sellItem({
            variables: { id: getGame?.id },
          });

          playAudio(null, '7.mp3');

          if (_.isFunction(onDone)) {
            onDone();
          }
        } catch (errors) {
          for (const error of errors.graphQLErrors) {
            if (error?.extensions?.type === 'PAY_FREE_CASE') {
              navigate('/case/free');

              return toast(
                <>
                  <div className="notify-title">
                    {t('Error - almost there')}!
                  </div>
                  <div
                    className="notify-text"
                    dangerouslySetInnerHTML={{ __html: t(error.message) }}
                  />
                </>,
              );
            }

            toast.error(
              <>
                <div className="notify-title">{t('Error')}!</div>
                <div
                  className="notify-text"
                  dangerouslySetInnerHTML={{ __html: t(error.message) }}
                />
              </>,
            );
          }
        }
      };

      if (type === 0) {
        return (
          <Button
            ref={ref}
            data-event="onSellItem"
            onClick={onSellItem}
            className={`${className} ${
              getGame?.status !== 1 && 'btn-disabled'
            }`}
            onMouseEnter={playAudio}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            data-audio="7.mp3"
            loading={loadingSellItem}
            {...rest}
          >
            {children}
          </Button>
        );
      } else if (type === 1) {
        return (
          <Button
            ref={ref}
            data-event="onSendItem"
            onClick={onSendItem}
            className={`${className} ${
              getGame?.status !== 1 && 'btn-disabled'
            }`}
            onMouseEnter={playAudio}
            data-audio="7.mp3"
            loading={loadingSendItem}
            {...rest}
          >
            {children}
          </Button>
        );
      } else if (type === 3) {
        if (getGame?.getCase?.type === 3 && !getProfile?.accessFreeCase) {
          return (
            <Link
              to={getGame?.getCase?.getUrl}
              className={`${className} ${
                getGame?.status !== 1 && 'btn-disabled'
              }`}
              onMouseEnter={playAudio}
              data-audio="7.mp3"
              onClick={goFree}
            >
              {loadingSendItem ? <Loader /> : children}
            </Link>
          );
        }

        return (
          <Button
            ref={ref}
            onClick={viewModal}
            data-type="OutItemModal"
            className={`${className} ${
              getGame?.status !== 1 && 'btn-disabled'
            }`}
            onMouseEnter={playAudio}
            data-audio="7.mp3"
            {...rest}
          >
            {loadingSendItem ? <Loader /> : children}
          </Button>
        );
      }

      return (
        <Button
          ref={ref}
          className={`${className}`}
          onClick={onSellAllItems}
          data-type="OutItemModal"
          onMouseEnter={playAudio}
          data-audio="7.mp3"
          {...rest}
        >
          {children}
        </Button>
      );
    },
  ),
);
export default GameChange;
