import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import playAudio from '~frontend/utils/playAudio';

import IconMenuNew from '../../scss/images/icon-badge-new.svg?react';
import IconMenu1 from '../../scss/images/icon-menu-1.svg?react';
import IconMenu2 from '../../scss/images/icon-menu-2.svg?react';
import IconMenu4 from '../../scss/images/icon-menu-4.svg?react';
import IconMenu8 from '../../scss/images/icon-menu-8.svg?react';
import IconEarn from '../../scss/images/icon-menu-14.svg?react';
import IconMenu9 from '../../scss/images/icon-menu-battlepass-2.svg?react';
import { useConfig } from '../hooks';

const TopMenu: FunctionComponent = () => {
  const getConfig = useConfig();
  const { t } = useTranslation();

  const isMobile =
    getConfig?.userAgent.match(
      /Android|BlackBerry|iPhone|iPod|Opera Mini|IEMobile|WPDesktop/i,
    ) ?? false;

  return (
    <nav className="header-menu">
      {getConfig?.getBattlePass && (
        <NavLink
          to={getConfig?.getBattlePass.getUrl}
          onMouseEnter={playAudio}
          data-audio="3.mp3"
          data-volume="0.15"
          className="header-menu__item menu-battle-pass"
        >
          <div className="header-menu__item-icon">
            <IconMenu9 />
          </div>
          <span
            className="header-menu__item-name"
            dangerouslySetInnerHTML={{ __html: t('Battle<br>pass') }}
          />
        </NavLink>
      )}
      <a
        href="/referral"
        target="_blank"
        rel="nofollow"
        onMouseEnter={playAudio}
        data-audio="3.mp3"
        data-volume="0.15"
        className="header-menu__item promocode"
      >
        <IconMenuNew className="icon-badge" />
        <div className="header-menu__item-icon">
          <IconMenu1 />
        </div>
        <span className="header-menu__item-name">{t('Earn')}</span>
      </a>

      <NavLink
        to="/wheel"
        onMouseEnter={playAudio}
        data-audio="3.mp3"
        data-volume="0.15"
        className="header-menu__item wheel_link"
      >
        <div className="header-menu__item-icon">
          <IconEarn />
        </div>
        <span className="header-menu__item-name">{t('Bonus_Wheel')}</span>
      </NavLink>

      {/* <NavLink
        to="/contract"
        onMouseEnter={playAudio}
        data-audio="3.mp3"
        data-volume="0.15"
        className="header-menu__item contract"
      >
        <div className="header-menu__item-icon">
          <IconMenu2 />
        </div>
        <span className="header-menu__item-name">{t('Contracts')}</span>
      </NavLink>

      <NavLink
        to="/upgrade"
        onMouseEnter={playAudio}
        data-audio="3.mp3"
        data-volume="0.15"
        className="header-menu__item apgrate"
      >
        <div className="header-menu__item-icon">
          <IconMenu4 />
        </div>
        <span className="header-menu__item-name">{t('Upgrades')}</span>
      </NavLink> */}
    </nav>
  );
};

export default TopMenu;
