import 'dayjs/locale/ru';

import { hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { YaMetricaProvider } from './provider/yametrica';
import { i18Next } from './i18n';
import { I18nextProvider } from 'react-i18next';
import { ApolloProvider } from '@apollo/client';
import { loadableReady } from '@loadable/component';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import RenderRoutes from './router';
import { ModalsProvider } from './components/modals';

import client from './apollo/config';

import './scss/main.scss';
import { HttpProvider } from '~shared/frontend/providers';

if (Number(process.env.GAME_TYPE) === 570) {
  require('./scss/base/_dota.scss');
}

const container = document.getElementById('root');

Sentry.init({
  dsn: 'https://dc15bebb13404a5b9c763d39c9293293@o4504252551004160.ingest.sentry.io/4504252553428992',
  integrations: [new BrowserTracing(), new Sentry.Replay()],

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
});

loadableReady(() => {
  hydrateRoot(
    container,
    <HttpProvider client={{}}>
      <ApolloProvider client={client}>
        <CookiesProvider>
          <I18nextProvider i18n={i18Next}>
            <YaMetricaProvider>
              <ModalsProvider>
                <BrowserRouter
                  basename={
                    window.defaultLanguage === 'ru'
                      ? undefined
                      : i18Next.language === 'en'
                      ? undefined
                      : i18Next.language
                  }
                >
                  <RenderRoutes />
                </BrowserRouter>
              </ModalsProvider>
            </YaMetricaProvider>
          </I18nextProvider>
        </CookiesProvider>
      </ApolloProvider>
    </HttpProvider>,
  );
});
